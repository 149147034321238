<template>
<div>
  <div class="vx-row">
            <div class="vx-col sm:w-full md:w-1/2">
              <table class="info">
                <tr>
                  <td class="font-semibold">Job Order No.</td>
                  <td>{{dt.code}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Created At</td>
                  <td>{{dt.created_at | moment("dddd, Do MMMM YYYY")}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Company / Branch</td>
                  <td>{{dt.company}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Customer</td>
                  <td class="font-semibold">{{dt.customer}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Shipper</td>
                  <td>{{dt.shipper}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Consignee</td>
                  <td>{{dt.consignee}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Bill To</td>
                  <td class="font-bold">{{dt.billing}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Description</td>
                  <td class="font-bold">{{dt.description}}</td>
                </tr>
              </table>
            </div>
            <div class="vx-col sm:w-full md:w-1/2">
              <table class="info">
                <tr>
                  <td class="font-semibold">Order Date.</td>
                  <td>{{dt.shipment_date | moment("DD/MM/YYYY HH:mm")}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Email Received</td>
                  <td>{{dt.email_received | moment("DD/MM/YYYY HH:mm")}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">SIPB Received</td>
                  <td>{{dt.sipb_received | moment("DD/MM/YYYY HH:mm")}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Shipment No.</td>
                  <td>{{dt.no_shipment}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Route</td>
                  <td>{{dt.route}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Mode</td>
                  <td>{{dt.mode}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Vehicle Type</td>
                  <td>{{dt.vehicle_type}}</td>
                </tr>
                <tr>
                  <td class="font-semibold">Total Price</td>
                  <td><span style="font-size: 20px;">{{dt.total_price | numFormat}}</span></td>
                </tr>
                <tr>
                  <td class="font-semibold">Invoice Code</td>
                  <td>{{dt.invoice_code}}</td>
                </tr>
              </table>
            </div>
          </div>
          <vs-divider></vs-divider>
          <div>
              <h4>Goods / Commodities</h4>
              <div slot="actions">
                  <vs-button type="gradient" :to="{name: 'job_orders-add-item', params: {job_order_id: $route.params.id}}" class="ml-2 float-right" color="primary">Create Items</vs-button>
              </div>

              <div>
                  <vs-tabs>
                    <vs-tab label='Items'>
                        <vs-table :data="details" class="mb-3">
                          <template slot="thead">
                            <vs-th sort-key="commodity">Goods / Commodity</vs-th>
                            <vs-th sort-key="qty">Qty</vs-th>
                            <vs-th sort-key="qty">Transported</vs-th>
                            <vs-th sort-key="unit">Unit</vs-th>
                            <vs-th sort-key="weight">Weight (Kg)</vs-th>
                            <vs-th sort-key="volume">Volume (m<sup>3</sup>)</vs-th>
                            <vs-th sort-key="imposition_name">Imposition</vs-th>
                            <vs-th sort-key="price">Price</vs-th>
                            <vs-th sort-key="total_price">Total Price</vs-th>
                            <vs-th sort-key="qrcode">Qrcode / Barcode</vs-th>
                            <vs-th sort-key="description">Description</vs-th>
                            <vs-th></vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                              <vs-td :data="tr.commodity">{{tr.commodity}}</vs-td>
                              <vs-td label="Qty" class='text-right' :data="tr.qty">{{tr.qty}}</vs-td>
                              <vs-td label="Transported" class='text-right' :data="tr.qty">{{tr.transported}}</vs-td>
                              <vs-td label="Unit" :data="tr.unit">{{tr.unit}}</vs-td>
                              <vs-td label="Weight (Kg)" class='text-right' :data="tr.weight">{{tr.weight}}</vs-td>
                              <vs-td label="Volume (m<sup>3</sup>)" class='text-right' :data="tr.volume">{{tr.volume ? parseFloat(tr.volume).toFixed(3) : 0}}</vs-td>
                              <vs-td label="Imposition" :data="tr.imposition_name">{{tr.imposition_name}}</vs-td>
                              <vs-td label="Price" class='text-right' :data="tr.price">{{tr.price | numFormat}}</vs-td>
                              <vs-td label="Total Price" class='text-right' :data="tr.total_price">{{tr.total_price | numFormat}}</vs-td>
                              <vs-td label="Qrcode / Barcode" :data="tr.qrcode">{{tr.qrcode}}</vs-td>
                              <vs-td label="Description" :data="tr.description">{{tr.description}}</vs-td>
                              <vs-th>
                                <vs-button size="small" type="line" icon-pack="feather" icon="icon-edit" color="primary" :to='{name:"job_orders-edit-item", params : {job_order_id:$route.params.id, id:tr.id}}'></vs-button>
                                <vs-button size="small" type="line" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteItem(tr.id)"></vs-button>
                              </vs-th>
                            </vs-tr>
                          </template>
                        </vs-table>
                    </vs-tab>

                    <vs-tab  label='Additional'>
                        <vs-table :data="details" class="mb-3">
                          <template slot="thead">
                            <vs-th sort-key="commodity">Goods / Commodity</vs-th>
                            <vs-th>Info</vs-th>
                            <vs-th></vs-th>
                          </template>
                          <template slot-scope="{data}">
                            <vs-tr :key="indextr" v-for="(tr, indextr) in data.filter(item => { return item.commodity_type === 'car' || item.commodity_type === 'sparepart'})">
                              <vs-td :data="tr.commodity">{{tr.commodity}}</vs-td>
                              <vs-td label="Info">
                                <div v-if="tr.commodity_type == 'car'">
                                  <span><strong>No Faktur:</strong></span> {{tr.no_faktur}}<br/><hr/>
                                  <span><strong>Faktur Date:</strong></span> {{tr.faktur_date}}<br/><hr/>
                                  <span><strong>Car Factory:</strong></span> {{tr.car_factory_name}}<br/><hr/>
                                  <span><strong>Car Type:</strong></span> {{tr.car_type_name}}<br/><hr/>
                                  <span><strong>No Chasis:</strong></span> {{tr.no_chasis}}<br/><hr/>
                                  <span><strong>No Machine:</strong></span> {{tr.no_machine}}<br/><hr/>
                                  <span><strong>Exterior:</strong></span> {{tr.car_color_name}}<br/><hr/>
                                  <span><strong>Interior:</strong></span> {{tr.interior}}
                                </div>
                                <div v-if="tr.commodity_type == 'sparepart'">
                                  <span><strong>Car Sparepart:</strong></span> {{tr.car_sparepart_name}}
                                </div>
                              </vs-td>
                              <vs-th>
                                <vs-button size="small" type="line" icon-pack="feather" icon="icon-edit" color="primary" :to='{name:"job_orders-edit-item", params : {job_order_id:$route.params.id, id:tr.id}}'></vs-button>
                                <vs-button size="small" type="line" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteItem(tr.id)"></vs-button>
                              </vs-th>
                            </vs-tr>
                          </template>
                        </vs-table>
                    </vs-tab>
                </vs-tabs>
              </div>
              <vs-divider></vs-divider>
              <h4>Job Order Cost</h4>
              <div>
                  <vs-button class='float-right' type="gradient" :to="{name: 'job_orders-add-cost', params:{id: $route.params.id}}">Add Cost</vs-button>
              </div>
              <div>
                  <vs-table :data="cost_lists" class="mb-3">
          <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th>Vendor</vs-th>
            <vs-th>Qty</vs-th>
            <vs-th>Cost</vs-th>
            <vs-th>Total Cost</vs-th>
            <vs-th>Description</vs-th>
            <vs-th>Status</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="i" v-for="(tr, i) in data">
              <vs-td>{{tr.name}}</vs-td>
              <vs-td label="Vendor">{{tr.vendor}}</vs-td>
              <vs-td label="Qty">{{tr.qty|numFormat}}</vs-td>
              <vs-td label="Cost">{{tr.price|numFormat}}</vs-td>
              <vs-td label="Total Cost">{{tr.total_price|numFormat}}</vs-td>
              <vs-td label="Description">{{tr.description}}</vs-td>
              <vs-td>
                <vs-chip v-if="tr.status==1" color="warning">Draft</vs-chip>
                <vs-chip v-if="tr.status==2" color="primary">Approved</vs-chip>
              </vs-td>
              <vs-td width="10">
                <div class="flex">
                  <vs-button v-if="tr.status==1" size="small" type="line" :to="{name:'job_orders-edit-cost', params:{id : $route.params.id,cost_id: tr.id}}" icon-pack="feather" icon="icon-edit"></vs-button>
                  <vs-button v-if="tr.status==1" size="small" type="line" icon-pack="feather" icon="icon-check-circle" color="primary" @click="approveCost(tr.id)"></vs-button>
                  <vs-button v-if="tr.status==1" size="small" type="line" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteCost(tr.id)"></vs-button>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>        
              </div>
          </div>
      </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data(){
    return {
      dt: {},
      job_order_detail_id:null,
      details: [],
      cost_lists: [],
      idDeleteCost: null,
      idApproveCost: null,
    }
  },
  methods:{
    ...mapActions({
      dispatchShow: 'job_orders/show',
      dispatchShowCommodities: 'job_orders/get_commodities',
      dispatchShowCosts: 'job_orders/get_costs'
    }),
    async getData(){
      let { data } = await this.dispatchShow(this.$route.params.id)
      this.dt = data
      if(data.delivery_order_id) {
          this.$emit('on-receive-shipment', data.delivery_order_id)
      }
    },
    async getDetails(){
      let { data } = await this.dispatchShowCommodities(this.$route.params.id)
      this.details = data
    },
    async getCosts(){
      let { data } = await this.dispatchShowCosts(this.$route.params.id)
      this.cost_lists = data
    },
    approveCost(id){
      this.idApproveCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `primary`,
        title: `Are you sure ?`,
        text: `This cost will be approved.`,
        accept: this.confirmApproveCost
      })
    },
    async confirmApproveCost(){
      try {
        await this.$store.dispatch('job_orders/approve_costs', this.idApproveCost)
        this.getCosts()
        this.$vs.notify({
          title: `Success`,
          text: `Your data has been approved successfully`,
          color: `primary`
        })
      } catch (error) {
        if(error.status == 422) {
              for(let x in error.data) {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data[x][0],
                    color: 'danger'
                  })
                  break
              }
         } else {
            this.$vs.notify({
              title: `Oops!`,
              text: error.data.message,
              color: `danger`
            })
         }
      }
    },
    deleteCost(id){
      this.idDeleteCost = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteCost
      })
    },
    deleteItem(id){
      this.job_order_detail_id = id
      this.$vs.dialog({
        type: `confirm`,
        color: `danger`,
        title: `Are you sure ?`,
        text: `Deleted data can no longer be restored`,
        accept: this.confirmDeleteItem
      })
    },
    async confirmDeleteCost(){
          try {
            await this.$store.dispatch('job_orders/delete_costs', this.idDeleteCost)
            this.getCosts()
            this.$vs.notify({
              title: `Success`,
              text: `Your data has been deleted successfully`,
              color: `primary`
            })
          } catch (error) {
            this.$vs.notify({
              title: `Oops!`,
              text: `Looks like something went wrong. please try again later (${error.data.message})`,
              color: `danger`
            })
          }
    },
    async confirmDeleteItem(){
          try {
            await this.$store.dispatch('job_orders/delete_details', this.job_order_detail_id)
            this.getDetails()
            this.$vs.notify({
              title: `Success`,
              text: `Your data has been deleted successfully`,
              color: `primary`
            })
          } catch (error) {
            this.$vs.notify({
              title: `Oops!`,
              text: error.data.message,
              color: `danger`
            })
          }
    },
  },
  mounted(){
    this.getData()
    this.getDetails()
    this.getCosts()
  }
}
</script>

<style lang="scss">
  
</style>