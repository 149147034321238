<template>
    <div class="vx-row">
        <div class="vx-col w-full mb-base">
            <vx-card title="">
                <vs-tabs>
                    <vs-tab label="Information">
                        <detail @on-receive-shipment='changeShipment' class='mt-3' />
                    </vs-tab>
                    <vs-tab label="Packing List">
                        <manifest class='mt-3' :job_order_id='$route.params.id' />
                    </vs-tab>
                    <vs-tab label="Files">
                        <files-index class='mt-3' />
                    </vs-tab>
                    <vs-tab label="Shipments" v-if='delivery_order_id'>
                        <shipment class='mt-3' :hide_manifest='true' :delivery_order_id='delivery_order_id' />
                    </vs-tab>
                </vs-tabs>
            </vx-card>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Detail from './sections/Detail.vue'
import Manifest from '../manifests/Index.vue'
import FilesIndex from './sections/FilesIndex.vue'
import Shipment from '@pages/operational/shipments/Show'

export default {
  data() {
    return {
        delivery_order_id : null
    }
  },
  components : {
     Detail,
     Manifest,
     Shipment,
     FilesIndex
  },
  methods : {
    changeShipment(id) {
        this.delivery_order_id = id
    }
  }
}
</script>

<style lang="scss">
  
</style>